import { Link, graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class TicketsPage extends React.Component {
    render() {
        const { data } = this.props
        const edition = data.edition
        return (
            <Layout>
                <Helmet title={`Tickets | ${edition.name}`} />
                <div>
                    <h1 className="page-header tickets-page-header">Join us at Canada’s UX Festival </h1>

                    <div className="ticket-section">
                        <div className="ticket-card-container">
                            <div className="tickets-item">
                                <h2 className="card-header">
                                    <span className="ticket-strong">Fluxible Conference</span><br />
                                    For everyone<br />
                                </h2>

                                <p className="tickets-pricing">$495 early bird (phase 3) price</p>

                                <p>Two days of single-track programming on June 6–7</p>

                                <p>Lunches + Saturday night dinner and party</p>

                                <a
                                    href="https://fluxible.eventbrite.com"
                                    className="button action-button"
                                    aria-label="Register as general attendee"
                                >
                                    Register Now
                                </a>
                            </div>

                            <div className="tickets-item">
                                <h2 className="card-header">
                                    <span className="ticket-strong">Fluxible Conference</span><br />
                                    For Students<br />
                                </h2>

                                <p className="tickets-pricing">$195 student price</p>

                                <p>Special offer for the next generation of UX'ers!</p>

                                <p>Requires proof of status as a full-time student.</p>

                                <a
                                    href="https://fluxible.eventbrite.com"
                                    className="button action-button"
                                    aria-label="Register as student attendee"
                                >
                                    Register Now
                                </a>
                            </div>

                            <div className="tickets-item">
                                <h2 className="card-header">
                                    <span className="ticket-strong">Fluxible Workshops</span><br />
                                    Half day<br />
                                </h2>

                                <p className="tickets-pricing">$195 per workshop</p>

                                <p><Link to="/fluxible-2020/program-workshops/">Morning and afternoon workshops available</Link> with tickets on sale now!</p>

                                <p>Includes supplies &amp; refreshments</p>

                                <a
                                    href="https://fluxibleworkshops.eventbrite.com"
                                    className="button action-button"
                                    aria-label="Register for workshops"
                                >
                                    Register Now
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default TicketsPage

export const ticketsQuery = graphql`
  query TicketsPageQuery($currentEditionId: Int!) {
    edition: fluxibleEditions(alternative_id: { eq: $currentEditionId }) {
      name
      startDate
      endDate
    }
  }
`
